import styled from 'styled-components';
import rainbow from '../assets/rainbow.svg';


export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 135vh;
  overflow: hidden;
  padding: 0px;
  box-sizing: border-box;
`;

export const ContentWrapper = styled.div`
    width: 70%;
    height: 80vh;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    @media (max-width: 768px) {
        margin-top: 30px;
    }
`;

export const ContentHeaderWrapper = styled.div`
    text-align: center;
`;

export const H2Wrapper = styled.h2`
    font-size: 3rem;
    background-image: url(${rainbow});
    color: #070735;
    -webkit-text-fill-color: transparent;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    line-height: 4.5rem;
`;

export const ChatWrapper = styled.div`
  width: 70%;
  height: 80vh;
  overflow-y: auto;
  padding: 0px;
  border-radius: 0px;
`; 