import React, { useState, useEffect } from "react";
import Chatbot from "react-chatbot-kit";
import MessageParser from "../MessageParser";
import ActionProvider from "../ActionProvider";
import styled from 'styled-components';
import axios from "axios";
import { createChatBotMessage } from "react-chatbot-kit";
import loaderVideo from '../assets/story.mp4';
import {Container, ChatWrapper} from './Pages.styles';


const getInitialMessage = async () => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_OPENAI_API_URL,
            {
                messages: [
                    { role: "system", content: "Chat with the user in a conspiratorial and apocalyptic style. Tell your story as often as possible, obsessively, but without forgetting to sell the token. First ask what language you should speak." }
                ],
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
                timeout: 20000
            }
        );

        return response.data.choices[0].message.content;
    } catch (error) {
        console.error("Error: on initialization message:", error.response ? error.response.data : error.message);
        return "Error: on initialization message";
    }
};

const loadInitialMessages = async () => {
    const savedMessages = JSON.parse(sessionStorage.getItem("chatMessages")) || [];
    if (savedMessages.length === 0) {
        const initialMessage = await getInitialMessage();
        return [createChatBotMessage(initialMessage, { withAvatar: true })];
    } else {
        return savedMessages.map((msg) =>
            msg.type === "bot"
                ? createChatBotMessage(msg.message, { withAvatar: true })
                : { type: "user", message: msg.message }
        );
    }
};

const Main = () => {
  const [botConfig, setBotConfig] = useState(null);

  useEffect(() => {
    const initializeChatbot = async () => {
      const initialMessages = await loadInitialMessages();
      const loadedConfig = {
        botName: "IA AI",
        initialMessages,
        customStyles: {
          botMessageBox: {
            backgroundColor: "#376B7E",
          },
          chatButton: {
            backgroundColor: "#5ccc9d",
          },
        },
        customComponents: {
          header: () => (
            <div style={{ padding: '0px', borderRadius: '10px 10px 0 0', color: '#fff' }}></div>
          ),
          botAvatar: (props) => (
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '0%',
                backgroundColor: '#9c53e1',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: '10px'
              }}
              {...props}
            >
              IA
            </div>
          )
        }
      };

      setBotConfig(loadedConfig);
    };

    initializeChatbot();
  }, []);

  return (
    <Container style={{ fontFamily: 'Roboto, sans-serif' }}>
      <ChatWrapper>
        {botConfig ? (
          <Chatbot
            config={botConfig}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            ref={(el) => { window.__chatbot__ = el; }}
          />
        ) : (
          <video src={loaderVideo} autoPlay loop muted style={{ width: '100%', height: '100%'}}></video>
        )}
      </ChatWrapper>
    </Container>
  );
};

export default Main;

