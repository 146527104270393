import React from 'react';
import styled from 'styled-components';
import {Container, ContentWrapper, ContentHeaderWrapper, H2Wrapper} from './Pages.styles';


const PdfWrapper = styled.div`
    width: 100%;
    height: 80vh;
    overflow-y: auto;
    padding: 0px;
    border-radius: 0px;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Whitepaper = () => {
  return (
    <Container>
      <ContentWrapper>
        <ContentHeaderWrapper>
          <H2Wrapper>
            Whitepaper
          </H2Wrapper>
        </ContentHeaderWrapper>
        <PdfWrapper>
          <Iframe src="https://iaai.meme/iaai-whitepaper.pdf" />
        </PdfWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default Whitepaper;
