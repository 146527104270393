import React from 'react';
import styled from 'styled-components';
import iaairobot from '../assets/IAAIrobot.jpg';
import {Container, ContentWrapper, ContentHeaderWrapper, H2Wrapper} from './Pages.styles';


const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  background: #f0f0f0;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 20px auto;
  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Эффект наведения */
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width: 200px;
    height: auto;
    flex-direction: column;
  }
`;

const CardTop = styled.div`
  display: flex;
  flex: 1;
`;

const CardLeft = styled.div`
  width: 60%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background: #fff;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CardRight = styled.div`
  height: 400px;
  width: 40%;
  display: flex;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
`;

const CardContent = styled.div`
  background: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
`;

function Projects() {
  const handleClickWebsite = () => {
    window.open('https://iaairobot.com', '_blank', 'noopener');
  };
  const handleClickTelegram = () => {
    window.open('https://t.me/iaairobot', '_blank', 'noopener');
  };
  return (
    <Container style={{ fontFamily: 'Roboto, sans-serif' }}>
      <ContentWrapper>
    <ContentHeaderWrapper>
      <H2Wrapper>
        Projects
      </H2Wrapper>
    </ContentHeaderWrapper>
    <Card>
      <CardTop>
        <CardLeft onClick={handleClickWebsite} style={{ backgroundImage: `url(${iaairobot})` }}>
        </CardLeft>
        <CardRight onClick={handleClickWebsite} >
          <Video autoPlay loop muted>
            <source src="https://iaai.meme/6637726920481676365.MP4" type="video/mp4" />No video support
          </Video>
        </CardRight>
      </CardTop>
      <CardContent>
        <p>TON blockchain-based project that merges robotics, NFTs, AI, and gaming. First C2E & AI FIGHT GAME <a href='https://t.me/iaairobot'>https://t.me/iaairobot</a></p>
      </CardContent>
    </Card>
      </ContentWrapper>
    </Container>
  );
}

export default Projects;
