import React, { useEffect, useState } from "react";
import { Route, Routes } from 'react-router-dom';
import "./App.css";
import Logo from './components/Logo';
import Header from './components/Header';
import Main from './pages/Main';
import Whitepaper from './pages/Whitepaper';
import Projects from './pages/Projects';
import Roadmap from './pages/Roadmap';
import Tokenomics from './pages/Tokenomics';

function App() {

    return (
        <div className="App">
            <Logo />
            <Header />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/whitepaper" element={<Whitepaper />} />
                <Route path="/roadmap" element={<Roadmap />} />
                <Route path="/tokenomics" element={<Tokenomics />} />
            </Routes>
        </div>
    );
}

export default App;