import React from 'react';
import styled from 'styled-components';
import {Container, ContentWrapper, ContentHeaderWrapper, H2Wrapper} from './Pages.styles';

function Tokenomics() {
  return (
    <Container style={{ fontFamily: 'Roboto, sans-serif' }}>
    <ContentWrapper>
    <ContentHeaderWrapper>
      <H2Wrapper>
        Allocation
      </H2Wrapper>
    </ContentHeaderWrapper>
    <p style={{ color: '#6e7b8b', fontSize: '1.5rem'}}>There are 200 million IAAI.MEME available on day one and will grow to a total of 1 billion IAAI.MEME over three years. </p>
    <h3 style={{ color: '#fe00fe' }}> Token symbol: <span style={{color: '#9c53e1'}}>IAAI</span></h3>
    <h3 style={{ color: '#fe00fe' }}> Token name: <span style={{color: '#9c53e1'}}>IAAI.MEME</span></h3>
    <h3 style={{ color: '#fe00fe' }}> Solana Contract Address: <span style={{color: '#9c53e1'}}>iaaiW5Vsjvkwzmx49ikB8E54eukdCgz8YWqUXTLTnPM</span></h3>
    <h3 style={{ color: '#fe00fe' }}> Token contract: <a href="https://solscan.io/token/iaaiW5Vsjvkwzmx49ikB8E54eukdCgz8YWqUXTLTnPM" target="_blank">https://solscan.io/token/iaaiW5Vsjvkwzmx49ikB8E54eukdCgz8YWqUXTLTnPM</a></h3>
    <h3 style={{ color: '#fe00fe' }}> Total supply: <span style={{color: '#9c53e1'}}>1,000,000,000</span></h3>
    <h3 style={{ color: '#fe00fe' }}> Token minting: <span style={{color: '#9c53e1'}}>2025-01-21</span></h3>
    </ContentWrapper>
  </Container>
  );
}

export default Tokenomics;