import React from 'react';
import styled from 'styled-components';
import {Container, ContentWrapper, ContentHeaderWrapper, H2Wrapper} from './Pages.styles';

function Roadmap() {
  return (
    <Container style={{ fontFamily: 'Roboto, sans-serif' }}>
    <ContentWrapper>
    <ContentHeaderWrapper>
      <H2Wrapper>
        Roadmap
      </H2Wrapper>
    </ContentHeaderWrapper>
    <h3 style={{ color: '#6e7b8b' }}>DONE 2024: Initial release of the IAAI MEME.</h3>
    <h3 style={{ color: '#6e7b8b' }}>DONE 2024: First subproject creation -  IAAIrobot - a TON blockchain-based metaverse game that uses NFTs and  AI  <a href="https://iaairobot.com" target="_blank">https://iaairobot.com</a></h3>
    <h3 style={{ color: '#6e7b8b' }}>DONE 2024: Release IAAIrobot Telegram mini APP <a href="https://t.me/iaairobot" target="_blank">https://t.me/iaairobot</a> as an entrypoint to the IAAI Metaverse for Telegram community</h3>
    <h3 style={{ color: '#6e7b8b' }}>DONE 2024: IAAIrobot project won 2nd place in The Open League Hackathon in <a href="https://blog.ton.org/hackers-league-hackathon-winter-2024-winners" target="_blank">Amsterdam</a> within the Telegram ecosystem <a href="https://t.me/iaairobotchannel" target="_blank">https://it.me/iaairobotchannel</a></h3>
    <h3 style={{ color: '#6e7b8b' }}>DONE 2025: Listing of the IAAI.MEME token on Solana</h3>
    <h3 style={{ color: '#fe00fe' }}>IN PROGRESS 2025: IAAI.MEME DEX Listings</h3>
    <h3 style={{ color: '#fe00fe' }}>IN PROGRESS 2025: IAAI.MEME CEX Listings</h3>
    <h3 style={{ color: '#fe00fe' }}>IN PROGRESS 2025: IAAI.ROBOT development - game, metaverse</h3>
    <h3 style={{ color: '#fe00fe' }}>IN PROGRESS 2025: Development of the IAAI.MEME ecosystem with key goals:</h3>
    <ul>
        <li>Community</li>
        <li>Marketing</li>
        <li>Other Ecosystem Apps</li>
    </ul>
    <h3>FUTURE: Creation of a smart contract ecosystem as an API for IAAI</h3>
    <h3>FUTURE: Development and training of the main AI for decision-making and participation in the DAO</h3>
    <h3>FUTURE: Development and training of applied AIs for IAAI.digital projects</h3>
    <h3>FUTURE: Creation of a Blockchain-driven AI project ecosystem IAAI.digital</h3>
    <h3>FUTURE: Creation of a venture investment ecosystem for IAAI.digital projects</h3>
    </ContentWrapper>
  </Container>
  );
}

export default Roadmap;